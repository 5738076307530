/*oE (vsechny stranky)*/
$zahlavipozadi: #F9F2D7;
$zahlavitext: #272727;
$nazev: #495844;
$dodatek: #495844;
$zahlaviodkazy: #495844;
$vyhledavaniobrys: #FECB00;
 
$hlavninadpisy: #495844;
 
$menupozadi: #5F641D;
$menuzvyraznenipozadi: #FFFFFF;
$menutlacitkapozadi: #FECB00;
$menutlacitkatext: #000000;
$menuzvyraznenitext: #000000;
 
$upozornenipozadi: #FFFFFF;
$upozorneninadpisy: #5F6922;
$upozornenitext: #000000;
$upozorneniodkazy: #5F6922;
 
$aktuality1nadpisy: #73782C;
$linky: #FECB00;
$bileplochyodkazy: #5F5F18;
 
$deskalinky: #73782C;
 
$kalendarakcipozadi: #FDF2CD;
$akcehlavninadpis: #495844;
$akcenadpisy: #696922;
$kalendarakcitext: #272727;
$akcedoplnkovytext: #494946;
$odkazyTabulekOstatni: #64641D;
$odkazyTabulekAktualni: #FFFFFF;
$bunkyTabulekOstatni: #FFFFFF;
$bunkyTabulekAktualni: #73782C;
$akcetlacitkapozadi: #FECB00;
$akcetlacitkatext: #000000;
$akceodkazy: #55550E;
 
$formularepozadi: #FDFCF0;
$formularetext: #272727;
$formularenadpis: #495844;
$polepozadi: #FFFFFF;
$poletext: #555555;
$poleobrysy: #FFE614;
 
$tlacitkapozadi: #FECB00;
$tlacitkatext: #000000;
 
$vysokapatapozadi: #696E27;
$vysokapatatext: #FFFFFF;
$vysokapataodkazy: #FFFFFF;
$vysokapatanadpisy: #FFFFFF;
$vysokapatasymboly: #FECB00;
 
$patapozadi: #F9F2D7;
$patatext: #272727;
$pataodkazy: #5F5F18;
