
/*******************************
* KALENDÁŘE
*******************************/

$bunka: (100% / 7);

.section #kalendar {
	display: none;

	@include bp("> 600px") {
		display: block;
	}
}

/* #kalakci {
	p {
		clear: both;
	} */

	#kalakci {
		margin-bottom: 30px;
	}

	.mesice {
		@extend %reset;
		box-sizing: border-box;
		font-size: rem(30px);
		display: table;
		width: 100%;
		font-family: $font;
		line-height: .8;
		border: 1px $bunkyTabulekAktualni solid;
		background-color: $bunkyTabulekOstatni;
		table-layout: fixed;
		border-spacing: 0;
		margin-bottom: 10px;

		+ p {
			margin: 20px 0;
		}

		a {
			display: table-cell;
			padding: .35em 0;
			text-align: center;
			vertical-align: middle;
			text-decoration: none;
			text-transform: lowercase;

			&.sipka {
				width: $bunka;
				position: relative;
			}
		}

		@include link {
			color: $odkazyTabulekOstatni;
			text-decoration: none;
		}
		
		@include link-over {
			text-decoration: underline;
		}
	}

	.calcells {
		th {
			font-weight: bold;
			font-size: rem(17px) !important;
			color: #000000;
			background-color: transparent;
		}

		th,
		td {
			padding: $cal-cell-padding 0;
		}

		td {
			> a {
				padding: $cal-cell-padding 0;
				margin: -$cal-cell-padding 0;
			}

			&,
			> #{selector-link()} {
				background-color: $cal-title-month-background;
				color: $cal-title-month-text;
			}

			&.notmnth {
				background-color: $cal-title-notmonth-background;
				color: $cal-title-notmonth-text;

				> #{selector-link()} {
					background-color: $cal-title-notmonth-background;
					color: $cal-title-notmonth-text;
				}
			}

			&.curdate {
				border: 2px $cal-title-current-border solid;
				padding: 0;

				> a {
					padding: ( $cal-cell-padding - 2px ) 0;
					margin: -2px 0;
				}
			}

			&.wkday,
			&.notmnth {
                > #{selector-link()} {
                    background-color: $menutlacitkapozadi;
                    color: $menutlacitkatext;
                }	
				> #{selector-link-over()} {
					background-color: $cal-hover-background;
					color: $cal-hover-text;
                }
			}
		}
	}

	#stranka .calcells {
		th {
			color: $cal-title-notmonth-text;
			background-color: $cal-title-notmonth-background;
		}

		td {
			&.wkday,
			&.wkend {
				&,
				> #{selector-link()} {
					background-color: $cal-sub-month-background;
					color: $cal-sub-month-text;
				}
			}

			//&.notmnth {
			> #{selector-link()} {
				background-color: $cal-sub-notmonth-background;
				color: $cal-sub-notmonth-text;
			}
			//}

			&.curdate {
				border: 2px $cal-sub-current-border solid;
				border-color: $cal-sub-current-border;
			}

			&.wkday,
			&.wkend,
			&.notmnth {
                > #{selector-link()} {
                    background-color: $menutlacitkapozadi;
                    color: $menutlacitkatext;
                }	
				> #{selector-link-over()} {
					background-color: $cal-hover-background;
					color: $cal-hover-text;
                }
			}

			&.cell_selected {
				&,
				> #{selector-link-all} {
					background-color: $cal-sub-selected-background;
					color: $cal-sub-selected-text;
				}
			}
		}
	}

		// Nasledujici odkomentovat, pokud maji bubliny vylezat zleva
		.floatingBubbleElm {
			margin-left: -15px !important;
		}
//}
